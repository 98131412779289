<!--
 * @Description: 我的工单
 * @Author: luocheng
 * @Date: 2022-03-28 17:07:44
 * @LastEditors: zyf493 1799563585@qq.com
 * @LastEditTime: 2022-06-01 11:42:47
-->
<template>
  <div class="work-order" v-loading="loading">
    <div class="left">
      <SubitemTree
        v-if="treeData.length"
        :treeData="treeData"
        :defaultSelect="false"
        :title="'我的工单空间'"
        :withFilter="true"
        :isAdd="true"
        :isStatistics="true"
        v-model="selectKeys"
        :treeProps="treeProps"
        @setNodes="getNodes"
        @add="handleAdd"
        @on-trigger="handleMoreEvent"
      ></SubitemTree>
    </div>
    <div class="right">
      <div class="header">
        <div v-for="(item,index) in navList" :key="item.value" class="header-item" @click="handleClick(item)">
          <div class="header-item-box" :style="item.isline ? 'border-right:1px solid #E6E8F0' : ''">
            <i :class="[activeNavIndex === index + 1 ? 'activeNav' : '',item.icon]"><span
              class="title">{{ item.name }}</span></i>
          </div>
        </div>
      </div>
      <div class="content">
        <div v-if="activeNavIndex === 1" style="display: flex;flex-direction: column;height:100%;padding: 8px 16px">
          <div class="btn-nav" style="height:34px">
            <div class="btn-box">
              <div class="btn" v-for="(item,index) in btnList" :key="item.value"
                   :class="[activeBtnIndex === index + 1 ? 'activeBtn' : '']" @click="handleBtn(item.value)">
                {{ item.name }} {{ item.num ? `(${item.num})` : '' }}
              </div>
              <el-popover
                placement="bottom-start"
                width="300"
                :visible-arrow="false"
                trigger="click">
                <div>
                  <div style="padding:12px">
                    <el-form>
                      <p class="form-item-label">启动时间</p>
                      <el-form-item>
                        <el-date-picker
                          style="width: 100%" type="date"
                          v-model="params.start_time"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="请选择"></el-date-picker>
                      </el-form-item>
                      <p class="form-item-label">截止时间</p>
                      <el-form-item>
                        <el-date-picker
                          style="width: 100%" type="date"
                          v-model="params.end_time"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="请选择">
                        </el-date-picker>
                      </el-form-item>
                      <p class="form-item-label">工作状态</p>
                      <el-form-item>
                        <div class="screen-status">
                          <div v-for="(item,index) in screenConfig.statusList" :key="item.value"
                               :class="[activeStatus === index ? 'active' : '']" class="screen-item"
                               @click="handleClickStatus(item.value)">
                            {{ item.name }}
                          </div>
                        </div>
                      </el-form-item>
                      <p class="form-item-label" style="margin-top:-12px">紧急程度</p>
                      <el-form-item>
                        <div class="screen-status">
                          <div v-for="(item,index) in screenConfig.urgentList" :key="item.value"
                               :class="[activeUrgent === index ? 'active' : '']" class="screen-item"
                               @click="handleClickUrgent(item.value)">
                            {{ item.name }}
                          </div>
                        </div>
                      </el-form-item>
                    </el-form>
                    <div class="action-btn">
                      <el-button size="small" style="margin-right: 16px;width:124px" @click="handleReset()">重置</el-button>
                      <el-button size="small" type="primary" @click="handleSearch()" style="width:124px">查询</el-button>
                    </div>
                  </div>

                </div>
                <el-button slot="reference"><i class="iconfont iconshaixuan1 icon"></i><span
                  class="screen">+{{ 2 }}</span></el-button>
              </el-popover>
            </div>
            <div v-if="work_space_id>0">
              <el-button type="primary" size="small" icon="el-icon-plus" @click="publish">发起工单</el-button>
            </div>
          </div>
          <div style="margin-top:12px;flex:1;height: 100%;overflow-y: auto;margin-bottom: 60px;">
            <el-table
              ref="xTable"
              height="94%"
              :data="tableData"
              :header-cell-style="{'background-color': '#F2F5FC', height: '48px', 'text-align': 'left'}"
              @row-click="expandChild"
            >
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <div class="expanded">
                    <el-table
                      :data="scope.row.todo"
                      :key="scope.row.id + '-child'"
                      border
                      :header-cell-style="{'background-color': '#E8F0FE', height: '48px', 'text-align': 'left'}"
                      :cell-style="{'text-align': 'left'}"
                    >
                      <template class="empty-box" slot="empty">
                        <el-empty description="暂无数据"></el-empty>
                      </template>

                      <el-table-column label="序号" width="80" prop="id">
                        <template slot-scope="{ $index }">
                          {{ (page-1)*30 + scope.$index + 1 }}.{{ $index + 1 }}
                        </template>
                      </el-table-column>
                      <el-table-column label="任务名称" prop="name"></el-table-column>
                      <el-table-column label="任务编号" width="300" prop="step_node"></el-table-column>
                      <el-table-column label="责任人">
                        <template slot-scope="{ row }">
                          {{ getTargetUser(row.user_task) }}
                        </template>
                      </el-table-column>
                      <el-table-column label="当前状态" prop="status">
                        <template slot-scope="{ row }">
                          <div class="status"
                               :style="[{background: getSubStatusBack(row.status),color:getSubStatusColor(row.status)}]">
                            {{ getSubStatus(row.status) }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="执行方式">
                        <template slot-scope="{ row }">
                          {{ row.user_task.length>1 ? '多人处理':'单人处理' }}
                        </template>
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="{ row }">
                          <el-button type="text" @click="handleTaskView(scope.row.id,row)">查看</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="序号" width="80">
                <template slot-scope="{ $index }">
                  {{(page-1)*30 + ($index + 1)}}
                </template>
              </el-table-column>
              <el-table-column label="工单名称" prop="name"></el-table-column>
              <el-table-column label="工单编号" prop="code"></el-table-column>
              <el-table-column label="紧急程度" prop="urgent">
                <template slot-scope="{ row }">
                  <div v-if="row.urgent" class="urgenc" :style="'background:' + getUrgencyColor(row.urgent)">
                    {{ getUrgency(row.urgent) }}
                  </div>
                  <div v-else>
                    --
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="当前状态" prop="task_engine_status">
                <template slot-scope="{ row }">
                  <div v-if="row.task_engine_status >= 0" class="status"
                       :style="[{background: getStatusBack(row),color:getStatusColor(row)}]">
                    {{ getStatus(row) }}
                  </div>
                  <div v-else>
                    --
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="发起人" prop="user_name"></el-table-column>
              <el-table-column label="截止时间" prop="end_time"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="{ row }">
                  <el-button type="text" @click.stop="handleView(row)">查看</el-button>
                  <el-button v-if="activeBtnIndex === 2" type="text" @click.stop="handleDel(row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: left;margin-top: 9px">
              <el-pagination
								layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="size"
                :current-page="page"
                @current-change="pageSize">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :width="dialogWidth"
      append-to-body
      top="5vh"
      :custom-class="'common-dialog'">
      <div class="dialog-main" v-if="dialogVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
          <el-row :gutter="15">
            <el-col :span="8">
              <el-form-item label="工作空间名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工作空间图标" prop="icon">
                <el-select style="width: 100%" v-model="ruleForm.icon">
                  <el-option
                    v-for="item in TYPES"
                    :key="item.code"
                    :label="item.label"
                    :value="item.value">
                    <div><!-- 分部分项图标 -->
                      <img :src="getIcon(item)" class="node-icon"/>
                      <span class="label">{{ item.label }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="默认数据源" prop="source">
                <el-select style="width: 100%" v-model="ruleForm.source"></el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="template-wrapper">
          <TaskTemplate
            :taskEngineId="ruleForm.task_template_id"
            :disabled="true"
            @checked="checkTaskEngine">
          </TaskTemplate>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="dialogFolderTitle"
      :visible.sync="dialogFolderVisible"
      width="25%">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="工作目录名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="工作目录描述" prop="desc">
            <el-input type="textarea" v-model="ruleForm.desc"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFolderVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SubitemTree from './components/leftItem.vue';
import TaskTemplate from "@/custom-component/task-flow/TaskTemplate";
import {Popover, Form, FormItem, DatePicker, Empty, Pagination} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import {dataInterface} from "@/apis/data";
import { format } from 'date-fns';
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'All',
  components: {
    SubitemTree,
    'el-empty': Empty,
    'el-popover': Popover,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-date-picker': DatePicker,
    'el-pagination': Pagination,
    TaskTemplate
  },
  data() {
    return {
      loading: true,
      dialogFolderVisible: false,
      dialogFolderTitle: '新建工作目录',
      dialogVisible: false,
      dialogTitle: '新建工作空间',
      dialogWidth: '80%',
      ruleForm:{
        name:'',
        type: 1,
        icon:'industry_type',
        source:'',
        desc:'',
        task_template_id: 0,
      },
      rules: {
        name: [{required: true, message: '请输入空间名称', trigger: 'blur'},],
      },
      // 类型
      TYPES: [
        {
          code: 2,
          label: '版本',
          value: 'version',
          icon: require('@/assets/images/subitem/bbk.png')
        },
        {
          code: 3,
          label: '标记',
          value: 'project_type',
          icon: require('@/assets/images/subitem/xmlx.png')
        },
        {
          code: 12,
          label: '表格',
          value: 'table',
          icon: require('@/assets/images/subitem/table.png')
        },
        {
          code: 13,
          label: '文本',
          value: 'text',
          icon: require('@/assets/images/subitem/text.png')
        },
        {
          code: 14,
          label: '检查',
          value: 'xmlx',
          icon: require('@/assets/images/subitem/xmlx.png')
        }
      ],
      treeData: [],
      parentId: 0,
      navList: [
        {name: '列表视图', value: 1, isline: true, icon: 'el-icon-s-operation'},
        {name: '日历视图', value: 2, isline: true, icon: 'el-icon-date'},
        {name: '地图视图', value: 3, isline: true, icon: 'el-icon-place'},
        {name: '工单汇总', value: 4, isline: false, icon: 'el-icon-tickets'},
      ],
      btnList: [
        {name: '待处理', value: 1, num: 0},
        {name: '我发起的', value: 2, num: 0},
        {name: '已完成', value: 3, num: 0},
        {name: '全部', value: 4, num: 0},
        {name: '抄送我的', value: 5, num: 0},
      ],
      tableData: [],
      screenConfig: {
        statusList: [
          {name: '不限', value: 0},
          {name: '待处理', value: 1},
          {name: '处理中', value: 2},
          {name: '已滞后', value: 3},
          {name: '已完成', value: 4}
        ],
        urgentList: [
          {name: '不限', value: 0},
          {name: '高', value: 1},
          {name: '中', value: 2},
          {name: '低', value: 3},
        ],
      },
      selectKeys: [],
      treeProps: {
        label: 'name'
      },
      activeNavIndex: 1,
      activeBtnIndex: 1,
      activeStatus: 0,
      activeUrgent: 0,
      params: {
        start_time: '',
        end_time: ''
      },
      task_engine_id: 0,
      editId: 0,
      work_space_id: 0,
      page: 1,
      size: 30,
      total: 0,
    }
  },
  computed: {
    ...mapGetters(["getWorkSpace"]),
    getTargetUser(){
      return function (data){
        const users = Array.from(data,item=>item.user_name)
        let username = users.join(',')
        return username
      }
    },
    getUrgency() {
      return function (urgency) {
        if (urgency == 1) {
          return '低'
        } else if (urgency == 2) {
          return '中'
        } else if (urgency == 3) {
          return '高'
        }
      }
    },
    getUrgencyColor() {
      return function (urgency) {
        if (urgency == 3) {
          return '#F55858'
        } else if (urgency == 2) {
          return '#5ACC78'
        } else if (urgency == 1) {
          return '#8B9CCC'
        }
      }
    },
    getStatus() {
      return function (row) {
        const status = row.task_engine_status
        const end_time = row.end_time
        const now = new Date();
        const nowDate = format(now, 'yyyy-MM-dd');
        if (status == 0) {
          if(nowDate > end_time){
            return '已滞后'
          }
          return '待处理'
        } else if (status == 1) {
          return '已处理'
        } else if (status == 2) {
          return '进行中'
        }
      }
    },
    getSubStatus() {
      return function (status) {
        if (status == 0) {
          return '待处理'
        } else if (status == 1) {
          return '已处理'
        }
      }
    },
    getStatusBack() {
      return function (row) {
        const status = row.task_engine_status
        const end_time = row.end_time
        const now = new Date();
        const nowDate = format(now, 'yyyy-MM-dd');
        if (status == 0) {
          if(nowDate > end_time){
            return '#FFC1C1'
          }
          return '#E0F4FF'
        } else if (status == 1) {
          return '#E1F5E7'
        } else if (status == 2) {
          return '#FFD39B'
        }
      }
    },
    getSubStatusBack() {
      return function (status) {
        if (status == 0) {
          return '#E0F4FF'
        } else if (status == 1) {
          return '#E1F5E7'
        }
      }
    },
    getStatusColor() {
      return function (row) {
        const status = row.task_engine_status
        const end_time = row.end_time
        const now = new Date();
        const nowDate = format(now, 'yyyy-MM-dd');
        if (status == 0) {
          if(nowDate > end_time){
            return '#FF3030'
          }
          return '#0088CC'
        }else if (status == 1) {
          return '#21A35D'
        }
      }
    },
    getSubStatusColor() {
      return function (status) {
        if (status == 0) {
          return '#0088CC'
        }else if (status == 1) {
          return '#21A35D'
        }
      }
    },
  },
  created() {
    this.initSpaceData()
  },
  mounted() {
    const selectKey = this.getWorkSpace?.work_space_id || 0
    if(selectKey){
      this.work_space_id = selectKey
      let taskId = this.getWorkSpace?.task_engine_id || 0
      const data = {
        id: this.getWorkSpace?.work_space_id || 0,
        type: this.getWorkSpace?.work_space_type || 1,
        source: {task_template_id: taskId}
      }
      this.selectKeys.push(+selectKey)
      this.getNodes(data)
    } else {
      this.work_space_id = 0
      this.getDataList()
    }
  },
  methods: {
    ...mapMutations(["setWorkSpace"]),
    expandChild(row){
      this.$refs.xTable.toggleRowExpansion(row)
    },
    checkTaskEngine(taskEngineId){
      this.ruleForm.task_template_id = taskEngineId
    },
    handleMoreEvent(eventName,row){
      this.parentId = 0
      this.editId = 0
      let {name,type,icon,source,desc,task_template_id} = row.source
      switch (eventName){
        case 'add':
          this.parentId = row.id
          this.ruleForm={
            name:'', type: 1, icon:'industry_type', source:'', desc:'',task_template_id:0
          }
          this.dialogFolderVisible = true
          break
        case 'addSpace':
          this.parentId = row.id
          this.ruleForm={
            name:'', type: 2, icon:'table', source:'', desc:'',task_template_id:0
          }
          this.dialogVisible = true
          break
        case 'edit':
          this.editId = row.id
          this.ruleForm = {name,type,icon,source,desc,task_template_id}
          this.dialogFolderVisible = true
          break
        case 'editSpace':
          this.editId = row.id
          this.ruleForm = {name,type,icon,source,desc,task_template_id}
          this.dialogVisible = true
          break
        case 'del':
          this.del(row)
          break
      }
    },
    del(row){
      this.$confirm('此操作删除此工单空间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          __method_name__: 'deleteData',
          object_uuid: 'object629727c171052',
          data_id: row.id,
          transcode: 0
        }
        dataInterface(params).then((res) => {
          if(res.data.code === 200){
            this.initSpaceData()
          }
        })
      })
    },
    initSpaceData(){
      this.loading = true
      function getLeafCount(data,total = 0){
        let nums = data?.total_num || 0
        total += parseInt(nums)
        const haveChildren = Array.isArray(data.children) && data.children.length > 0;
        if(haveChildren){
          for (let item of data.children){
            total = getLeafCount(item,total)
          }
        }
        return total
      }
      function mapTree (data) {
        const haveChildren = Array.isArray(data.children) && data.children.length > 0;
        return {
          id: data.id, name: data.name, type: data.type,
          icon: data.icon, num: getLeafCount(data), source: data,
          children: haveChildren ? data.children.map(i => mapTree(i)) : []
        }
      }
      dataInterface({},'/api/taskengine/myworkspace','GET').then(res=>{
        if(res.data.code === 200){
          let treeData =  res.data.data.map(item => mapTree(item));
          this.treeData = treeData
        }
        this.loading = false
      })
    },
    pageSize(page){
      this.page = page
      const param = this.getParams()
      this.getDataList(param)
    },
    getDataList(param = {todo: true}){
      this.loading = true
      param.page = this.page
      dataInterface(param,'/api/taskengine/workorder','GET').then(res=>{
        if(res.data.code === 200){
          this.tableData = res.data.data.data
          this.total = res.data.data.total
          this.size = res.data.data.per_page
          this.loading = false
        }
      })
    },
    submitForm(){
      if(this.ruleForm.type === 2 && this.ruleForm.task_template_id === ''){
        this.$message.error('工作空间未选择任务引擎模板')
        return
      }
      this.$refs.ruleForm.validate((valid)=>{
        if(valid) {
          const data = {
            __method_name__: 'createData',
            object_uuid:'object629727c171052',
            transcode: 0,
            ...this.ruleForm
          }
          if(this.editId>0){
            data.__method_name__='updateData'
            data.data_id = this.editId
          } else {
            data.parent_id = this.parentId
          }
          dataInterface(data).then(res=>{
            if(res.data.code === 200){
              this.initSpaceData()
              this.dialogVisible = false
              this.dialogFolderVisible = false
            }
          })
        }
      })
    },
    resetForm(){},
    publish() {
      this.$router.push({name: 'WorkOrderEditor'})
    },
    /**
     * @desc: 获取树形图选中的节点
     * @param {Array} data 选中的节点ID
     */
    getNodes(data) {
      console.log(data, '获取树形图选中的节点')
      this.work_space_id = data.id
      this.parentId = data.id
      this.activeBtnIndex = 1
      const param = this.getParams()
      param.space_id = data.id
      this.getDataList(param);

      this.task_engine_id = data.source?.task_template_id || 0
      const workSpace = {
        work_space_type: data.type,
        work_space_id: data.id,
        task_engine_id: data.source?.task_template_id || 0,
      }
      this.setWorkSpace(workSpace)
    },
    //新增左侧列表
    handleAdd() {
      this.parentId = 0
      this.dialogFolderVisible = true
    },
    handleClick(data) {
      this.activeNavIndex = data.value
    },
    getParams(){
      let param = {
        ...this.params,
        status:this.activeStatus,
        urgent:this.activeUrgent
      }
      if(this.work_space_id>0){
        param.space_id = this.work_space_id
      }
      switch (this.activeBtnIndex){
        case 1:
          param.todo = true
          break;
        case 2:
          param.publish = true
          break;
        case 3:
          param.status = 4
          break;
        case 4:
          param.all = true
          break;
        case 5:
          param.send = true
          break;
      }
      return param
    },
    handleBtn(index) {
      this.page = 1
      this.activeBtnIndex = index
      const param = this.getParams()
      this.getDataList(param);
    },
    handleView(data) {
      this.$router.push({
        name:'WorkOrderDetails',
        query:{id:data.id}
      })
    },
    handleDel(row){
      this.$confirm('此操作删除此工单数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          __method_name__: 'deleteData',
          object_uuid: 'object62946b8979e52',
          data_id: row.id,
          transcode: 0
        }
        dataInterface(params).then((res) => {
          if(res.data.code === 200){
            const param = this.getParams()
            this.getDataList(param)
          }
        })
      })
    },
    handleTaskView(id,data){
      this.$router.push({
        name:'WorkOrderDetails',
        query:{id:id, node:data.step_node}
      })
    },
    clickScreen() {

    },
    handleClickStatus(index) {
      this.activeStatus = index
    },
    handleClickUrgent(index) {
      this.activeUrgent = index
    },
    handleReset(){
      this.page = 1
      this.activeStatus = 0
      this.activeUrgent = 0
      this.activeBtnIndex = 0
      this.params = {
        start_time: '',
        end_time: ''
      }
      const param = this.getParams()
      this.getDataList(param);
    },
    handleSearch(){
      this.page = 1
      const param = this.getParams()
      this.getDataList(param)
    },
    /**
     * @desc: 获取对应节点ICON
     * @param {*}
     * @return {*}
     */
    getIcon(data) {
      return this.TYPES.find((ele) => ele.value === data.value).icon;
    }
  }
}

</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  height: auto;
  max-height: calc(100vh - 50px);

  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.expanded{
  padding: 0 45px;
}
.node-icon {
  height: 18px;
  width: 18px;
  margin-right: 5px;
  vertical-align:text-bottom;
}
.label {
  height: 100%;
  line-height: 30px;
  flex: 1;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.template-wrapper{
  height: 700px;
}

.work-order {
  width: 100%;
  height: 100%;
  display: flex;

  .left {
    width: 256px;
    height: 100%;
  }

  .right {
    flex: 1;
    height: 100%;
    position: relative;
    // display: flex;
    // flex-direction: column;
    .header {
      display: flex;
      align-items: center;
      // height: 40px;
      .header-item {
        width: 120px;
        height: 22px;
        padding: 9px 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292F3D;
        line-height: 22px;
        text-align: center;
        cursor: pointer;

        .header-item-box {
          width: 100%;
          // border-right: 1px solid #E6E8F0;
          .title {
            display: inline-block;
            margin-left: 4px;
          }
        }

      }
    }

    .content {
      position: absolute;
      width: 100%;
      height: 100%;
      // margin-bottom: 10px;

      .btn-nav {
        display: flex;
        justify-content: space-between;

        .btn-box {
          display: flex;
          align-items: center;

          .btn {
            padding: 6px 20px;
            border-radius: 4px;
            margin-right: 16px;
            // background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #E6E8F0;
            cursor: pointer;
          }

          .el-button {
            margin-right: 16px;
          }


        }
      }

      .screen {
        // font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #337EFF;
      }

      .icon {
        font-size: 12px;
      }

      .urgenc {
        width: 32px;
        height: 20px;
        background: #8B9CCC;
        text-align: center;
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
      }

      .status {
        width: 52px;
        height: 24px;
        background: #E0F4FF;
        border-radius: 12px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
      }

    }
  }

  .activeNav {
    color: #337EFF;
  }

  .activeBtn {
    border-radius: 4px;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #F9890A;
    background: #FAEEDC;
    border: 1px solid #FAEEDC !important;
    line-height: 20px;
  }
}

.form-item-label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2F3D;
  // line-height: 22px;
  margin-bottom: 8px;
}

.screen-status {
  display: flex;
  flex-wrap: wrap;
}

.screen-item {
  text-align: center;
  padding: 3px 16px;
  background: #F2F4F8;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4E5466;
  line-height: 22px;
  cursor: pointer;
}

.action-btn {
  // display: flex;
}

.active {
  background: #D0E2FF;
  border-radius: 16px;
}
</style>
